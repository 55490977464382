<template>
  <div>
    <checklist-boat-details
      v-if="showBoat && !isGuest"
      :boat="checklist.crm_boat"
    ></checklist-boat-details>
    <v-row no-gutters class="ml-4">
      <v-col cols="12" v-if="checklist.name">
        <v-list class="mb-0 pb-0">
          <v-list-item-title single-line>
            <v-list-item-content>
              <v-list-item-title>{{ checklist.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item-title>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" v-if="checklist.crm_partner">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Professional:</v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="checklist.crm_partner">{{
                checklist.crm_partner.name
              }}</span>
              <span v-else>None selected</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" v-if="!isGuest">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Assignee:</v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="typeof checklist.assignee !== 'undefined'">{{
                checklist.assignee.email
              }}</span>
              <span v-else>No user</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>

    <v-form class="mb-2 ml-4">
      <v-row no-gutters v-for="(subtask, ind) in checklist.subtasks" :key="ind">
        <v-col cols="10">
          <v-checkbox
            v-model="subtask.completed"
            color="primary"
            @change="saveSubtask('checkbox', subtask)"
            :label="subtask.description"
            :disabled="disabled"
          ></v-checkbox
        ></v-col>
        <v-col cols="1" class="pt-2">
          <v-card-actions>
            <v-btn icon @click="openComment(subtask)">
              <v-icon>mdi-comment-text</v-icon>
            </v-btn>
          </v-card-actions>
        </v-col>
        <v-row no-gutters>
          <v-col cols="9" class="ml-8">
            <v-textarea
              class="pt-0 mt-0 pb-0 mb-0"
              color="primary"
              v-if="subtask.active"
              v-model="subtask.comment"
              rows="1"
              label="Comment"
              auto-grow
              @change="saveSubtask('comment', subtask)"
              :disabled="disabled"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-row>
    </v-form>
    <v-divider v-if="checklistIndex !== checklistLength - 1" light></v-divider>
  </div>
</template>

<script>
import ChecklistBoatDetails from "@/components/tasks/checklists/ChecklistBoatDetails.vue";
import api from "@/utils/api";

export default {
  components: {
    ChecklistBoatDetails,
  },
  props: {
    shared: {
      type: Boolean,
      default: true,
    },
    showBoat: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    checklist: {
      type: Object,
      default: Object,
    },
    checklistLength: {
      type: Number,
      default: Number,
    },
    checklistIndex: {
      type: Number,
      default: Number,
    },
    isGuest: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  created() {},
  computed: {},
  methods: {
    openComment(subtask) {
      this.$emit("update:subtask-comment", {
        uuid: subtask.uuid,
        index: this.checklistIndex,
        active: subtask.active,
      });
    },

    saveSubtask(event, subtask) {
      let data = {
        checklists: [
          {
            uuid: this.checklist.uuid,
            subtasks: [],
          },
        ],
      };

      let subtaskToUpdate = subtask;
      if (subtaskToUpdate.completed === true) {
        subtaskToUpdate.completed = new Date().toISOString();
      }
      if (subtaskToUpdate.completed === false) {
        subtaskToUpdate.completed = null;
      }
      if (subtaskToUpdate.comment === " ") {
        subtaskToUpdate.comment = "";
      }

      data.checklists[0].subtasks.push(subtaskToUpdate);

      this.$emit("update:content-saved", false);
      if (!this.shared) {
        api
          .saveSubtask(this.$route.params.id, data)
          .then((resp) => {
            this.$emit("update:content-saved", true);
          })
          .catch((err) => {
            this.$emit("update:content-saved", false);
          });
      } else {
        api
          .saveSharedSubtask(this.$route.params.id, data)
          .then((resp) => {
            this.$emit("update:content-saved", true);
          })
          .catch((err) => {
            this.$emit("update:content-saved", false);
          });
      }
    },
  },
};
</script>

<style></style>
