<template>
  <v-dialog v-model="dialog.active" persistent max-width="544">
    <v-card height="200" v-if="loading">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-row align="center" justify="center">
              <v-card flat>
                <v-progress-circular
                  :size="55"
                  :width="4"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card v-else>
      <v-card-title class="headline">Insert Image</v-card-title>
      <v-tabs color="primary" v-model="tab" centered>
        <v-tab>Upload</v-tab>
        <!-- <v-tab>By Url</v-tab> -->
        <v-tab>Camera</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-row no-gutters>
            <v-col>
              <v-row justify="center" align="start" class="ml-0 mr-0">
                <v-card flat>
                  <v-card-actions class="justify-center">
                    <v-img
                      src="https://ssl.gstatic.com/docs/picker/images/upload_background.png"
                      max-width="200"
                      max-height="200"
                    ></v-img>
                  </v-card-actions>
                  <v-card-actions class="mb-2">
                    <v-btn
                      color="primary"
                      dark
                      class="mx-auto"
                      @click="onButtonClick"
                    >
                      <v-icon left>
                        mdi-open-in-app
                      </v-icon>
                      Browse
                    </v-btn>
                    <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      accept="image/*"
                      @change="onFileChanged"
                    />
                  </v-card-actions>
                  <template v-if="selectedFiles.length !== 0">
                    <v-card-text
                      class="pa-0 ma-0"
                      v-for="file in selectedFiles"
                      :key="file.lastModified"
                    >
                      {{ file.name }}
                    </v-card-text>
                  </template>
                </v-card>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- <v-tab-item>
          <v-row no-gutters>
            <v-col cols="12">
              <v-row justify="center" align="start" class="ml-0 mr-0 mt-2">
                <v-card flat max-width="400" width="100%">
                  <v-card-actions class="mb-2">
                    <v-text-field
                      label="Paste URL of image"
                      color="primary"
                    ></v-text-field>
                  </v-card-actions>
                </v-card>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item> -->
        <v-tab-item>
          <v-row no-gutters>
            <v-col cols="12">
              <v-row justify="center" align="start" class="ml-0 mr-0">
                <v-card flat>
                  <v-card-actions class="mt-4 justify-center">
                    <v-icon x-large>mdi-camera-off</v-icon>
                  </v-card-actions>
                  <v-card-text>NO CAMERA FOUND</v-card-text>
                  <!-- <v-card-actions class="mb-2">
                        <v-text-field
                          label="Paste URL of image"
                          color="primary"
                        ></v-text-field>
                      </v-card-actions> -->
                </v-card>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="close()">Cancel</v-btn>
        <v-btn color="primary" text @click="upload()">Upload</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import api from "@/utils/api";
import snackbar from "@/utils/snackbar.js";
export default {
  props: ["dialog", "dataToSend"],
  data() {
    return {
      tab: null,
      isSelecting: false,
      selectedFiles: [],
      loading: false,
    };
  },
  created() {},

  computed: {},
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFiles.push(e.target.files[0]);
    },

    close() {
      this.$emit("close", true);
      this.loading = false;
    },
    upload() {
      let promises = [];
      let route = { name: this.$route.name, uuid: this.$route.params.id };
      const self = this;

      if (route.name === "TasksTemplatesNew") {
        this.$emit("data", this.selectedFiles);
        return;
      }

      this.selectedFiles.forEach((element, index, arr) => {
        self.loading = true;
        promises.push(
          new Promise(function(resolve, reject) {
            let formData = new FormData();
            formData.append("file", element);
            formData.append("title", element.name);
            if (route.name === "sharedTask") {
              api.sharedTaskAttachment(route.uuid, formData).then((resp) => {
                resolve(resp.data);
              });
            } else if (route.name === "charterTasksTemplatesEdit") {
              api
                .sharedTemplateAttachment(
                  self.dataToSend.templateUuid,
                  self.dataToSend.checklistUuid,
                  formData
                )
                .then((resp) => {
                  resolve(resp.data);
                });
            } else {
              api.taskAttachment(route.uuid, formData).then((resp) => {
                resolve(resp.data);
              });
            }
          })
        );
      });
      Promise.all(promises).then((values) => {
        this.$emit("added", values);
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped></style>
