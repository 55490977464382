<template>
  <div
    v-bind:class="{
      'my-12': !external,
      'd-flex': !external,
      'justify-center': !external,
    }"
  >
    <v-progress-circular
      :size="55"
      :width="4"
      indeterminate
      class="mt-12"
      v-if="loading"
      color="primary"
    ></v-progress-circular>
    <v-card v-else max-width="650px" width="650px">
      <v-card-title class="ml-4">
        <span class="headline">Task</span>

        <v-spacer></v-spacer>
        <v-card-actions v-if="!external">
          <v-btn color="primary" text @click="edit()"
            ><v-icon small class="mx-2">mdi-pencil</v-icon>Edit
          </v-btn>
          <v-btn text color="red" class="mr-1" @click="activateDeleteDialog()">
            <v-icon small class="mx-2">mdi-delete</v-icon>Delete</v-btn
          >
        </v-card-actions>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row no-gutters>
          <v-col cols="12">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Title:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ editedItem.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Status:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ getStatus }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Due date:</v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="editedItem.due_date">
                    {{
                      editedItem.due_date.slice(0, 10) | moment("DD.MM.YYYY")
                    }}</span
                  ><span v-else>Not defined</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Boat:</v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="editedItem.crm_boat !== null">
                    {{ editedItem.crm_boat.boat_name }}</span
                  ><span v-else>Not defined</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Duration:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ getDuration }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" v-if="editedItem.description">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Comment:</v-list-item-title>
                <v-list-item-subtitle>{{
                  editedItem.description
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <v-row>
          <v-col cols="12">
            <template v-for="(checklist, index) in editedItem.checklists">
              <checklist-details
                :key="index"
                :checklist-index="index"
                :checklist-length="editedItem.checklists.length"
                :checklist="checklist"
                @update:subtask-comment="updateSubtaskComment"
                :content-saved.sync="contentSaved"
                :shared="false"
                :show-boat="false"
                :disabled="checkFinishedTask"
              ></checklist-details>
            </template>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="editedItem.attachments.length">
          <v-col cols="12">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-if="editedItem.attachments.length"
                  class="mb-1"
                  >Attachments:</v-list-item-title
                >
                <v-list-item-action-text>
                  <template
                    v-for="(attachment, index) in editedItem.attachments"
                  >
                    <v-row :key="index">
                      <v-col cols="10">
                        <v-btn @click="openAttachment(attachment)" text
                          ><v-icon color="grey darken-2">mdi-file-image</v-icon>
                          <v-list-item-subtitle
                            class="text-none subtitle-2 ml-2"
                            >{{ attachment.title }}</v-list-item-subtitle
                          ></v-btn
                        >
                      </v-col>
                    </v-row>
                  </template>
                </v-list-item-action-text>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <template>
          <v-card-subtitle class="ml-1">
            <v-progress-circular
              :size="20"
              :width="3"
              indeterminate
              v-if="!contentSaved"
              color="primary"
            >
            </v-progress-circular>
            <span class="ml-2" v-if="!contentSaved && !external"
              >Saving changes</span
            >
            <span class="ml-2" v-if="contentSaved && !external"
              >All changes saved</span
            >
          </v-card-subtitle>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mr-4" text @click="close">Cancel</v-btn>
          <v-btn
            color="primary"
            v-if="editedItem.status !== 'COMPLETED' && !external"
            @click="uploadDialog"
            text
            >Upload image</v-btn
          >
          <v-btn
            color="primary"
            v-if="editedItem.status !== 'COMPLETED' && !external"
            @click="finish"
            text
            >Finish task</v-btn
          >
        </template>
      </v-card-actions>
    </v-card>
    <!-- </v-layout> -->
    <delete-dialog
      :dialog="deleteDialog"
      :item="taskName"
      @agree="remove"
      v-show="false"
    />
    <upload-tasks-file-dialog
      :dialog="uploadImageDialog"
      @close="uploadImageDialog.active = false"
      @added="handleUploadImageDialog"
      v-if="uploadImageDialog.active"
    ></upload-tasks-file-dialog>
    <warning-dialog
      :dialog="finishTaskDialog.active"
      :text="finishTaskDialog.text"
      :title="finishTaskDialog.title"
      @agree="handleFinishDialogOutput"
      :confirm="'Yes'"
      :reject="'Cancel'"
      v-show="false"
    />
  </div>
</template>

<script>
import api from "@/utils/api.js";
import snackbar from "../../utils/snackbar";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import UploadTasksFileDialog from "@/components/dialogs/UploadTasksFileDialog";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import { mapGetters, mapState } from "vuex";

import ChecklistDetails from "@/components/tasks/checklists/ChecklistDetails.vue";

export default {
  props: {
    external: {
      type: Boolean,
      default: false,
    },
    taskId: {
      type: String,
      default: String,
    },
  },
  components: {
    DeleteDialog,
    UploadTasksFileDialog,
    WarningDialog,

    ChecklistDetails,
  },
  data: () => ({
    finishTaskDialog: { active: false, title: "", text: "" },
    uploadImageDialog: { active: false },
    deleteDialog: false,
    taskName: "",
    statusOptions: [
      { status: "NOT_STARTED", name: "Not started" },
      { status: "IN_PROGRESS", name: "In progress" },
      { status: "COMPLETED", name: "Completed" },
    ],
    durations: [
      { duration: "15m", value: 15 },
      { duration: "30m", value: 30 },
      { duration: "45m", value: 45 },
      { duration: "1h", value: 60 },
      { duration: "2h", value: 120 },
      { duration: "4h", value: 240 },
      { duration: "8h", value: 480 },
      { duration: "12h", value: 720 },
      { duration: "16h", value: 960 },
      { duration: "24h", value: 1440 },
    ],
    loading: true,
    contentSaved: true,
    editedItem: {
      assignee: {},
      crm_partner_uuid: null,
      reports: [
        { engine_seconds_requested: "", crm_service: { short_task: "" } },
      ],
      subtasks: [],
      status: "",
    },
    params: null,
  }),

  computed: {
    getStatus() {
      return this.statusOptions.find(
        (item) => item.status === this.editedItem.status
      ).name;
    },
    checkFinishedTask() {
      return !!this.editedItem.completed;
    },
    getDuration() {
      let duration = this.durations.find(
        (item) => item.value === this.editedItem.duration
      );
      return typeof duration === "undefined"
        ? this.editedItem.duration
        : duration.duration;
    },
  },

  created() {
    if (this.external === true) {
      this.params = this.taskId;
    } else {
      this.params = this.$route.params.id;
    }

    api.getTask(this.params).then((resp) => {
      this.editedItem = resp.data;
      let crm_boat = null;
      this.editedItem.checklists.forEach((checklist) => {
        crm_boat = checklist.crm_boat;
        checklist.subtasks = checklist.subtasks.map((subtask) => {
          return {
            ...subtask,
            active: !!(subtask.comment && subtask.comment.length !== 0),
          };
        });
      });
      this.editedItem.crm_boat = crm_boat;
      this.editedItem.priority = Number(this.editedItem.priority);
      this.loading = false;
    });
  },

  methods: {
    updateSubtaskComment(subtask) {
      let index = this.editedItem.checklists[subtask.index].subtasks.findIndex(
        (element) => subtask.uuid === element.uuid
      );
      if (!subtask.active) {
        this.$set(
          this.editedItem.checklists[subtask.index].subtasks[index],
          "active",
          true
        );
      } else {
        this.$set(
          this.editedItem.checklists[subtask.index].subtasks[index],
          "active",
          false
        );
      }
    },
    openAttachment(attachment) {
      window.open(attachment.file_link, "_blank");
      return false;
    },
    handleUploadImageDialog(event) {
      event.forEach((element) => {
        this.editedItem.attachments.push(element);
      });
      this.uploadImageDialog.active = false;
    },

    uploadDialog() {
      this.uploadImageDialog.active = true;
    },

    close() {
      if (this.external) {
        this.$emit("close", true);
      } else {
        this.$router.push({
          name: "Tasks",
        });
      }
    },

    edit() {
      this.$router.push({
        name: "TasksEdit",
        params: { id: this.$route.params.id },
      });
    },

    finish() {
      this.finishTaskDialog.active = true;
      this.finishTaskDialog.title = "Are you sure you want to finish the task?";
    },

    handleFinishDialogOutput(status) {
      if (status) {
        api
          .finishTask(this.editedItem.uuid)
          .then((resp) => {
            snackbar.success("Successfully saved");
            this.close();
          })
          .catch((err) => {
            this.handleError(err.response);
          });
      }
      this.finishTaskDialog.active = false;
    },

    handleError(err) {
      if (err) {
        snackbar.error(err.response);
      } else {
        snackbar.error();
      }
    },

    activateDeleteDialog() {
      this.deleteDialog = true;
      this.taskName = "task " + this.editedItem.name;
    },

    remove(agreed) {
      if (agreed) {
        api.deleteTask(this.editedItem.uuid).then((resp) => {
          snackbar.success("Successfully deleted");
          this.close();
        });
      }
      this.deleteDialog = false;
    },
  },
};
</script>
<style scoped>
.comment--style {
  white-space: normal;
  height: 120px;
  overflow-y: auto;
}
.margin--left {
  margin-left: 300px;
}
</style>
